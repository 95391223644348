<template>
  <BrToolBar
    @search="buscar"
    :addlFilter="addlFilter"
    :configFilter="configFilter"/>
</template>

<script>
import toolbar from '@/views/mixins/toolbar'

export default {
  name: `ToolbarHistoricoRequisicaoPremioWeb`,
  mixins: [toolbar],
  components: { BrToolBar: () => import('@/views/components/toolbar') }
}
</script>