<template>
  <div>
    <v-container fluid>
      <h1 class="titulo">Histórico de Requisicao de Prêmio de Web</h1>

      <div class="box-table">
        <v-skeleton-loader
          height="500"
          type="table"
          :loading="loading"
          :transition="'fade-transition'">

          <v-data-table
            width="300px"
            flat
            hide-default-footer
            :headers="headers"
            :items="itens">

            <template v-slot:top>
              <Toolbar
                @search="submit"
                :labelSelects="'Filtrar concurso'"
                :itemsSelect="listaItensSelect"
                :addlFilter="true"
                :configFilter="{ listaSelect: itemSelectKeys, getItens: getItens, jsonData: false }"
                :labelTextField="'Busca por cod do concurso:'"
              />
            </template>

            <template v-slot:item.value="{ item }">{{ item.value | currency }}</template>

            <template v-slot:item.created="{ item }">{{ item.created | datas }}</template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </v-container>
  </div>
</template>

<script>
import Toolbar from '@/views/consultas/historicoRequisicaoPremioWeb/components/toolbar'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HistoricoRequisicaoPremioWeb',
  components: { Toolbar },
  data: () => ({
    loading: false,
    listaItensSelect: [],
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Nome do usúario', value: 'requestEntity.jsonData.fullName' },
      { align: 'start', class: 'table-header', text: 'Código da Revenda', value: 'payerEntity.cod' },
      { align: 'start', class: 'table-header', text: 'Valor(R$)', value: 'value' },
      { align: 'start', class: 'table-header', text: 'Created', value: 'created' },
      { align: 'start', class: 'table-header', text: 'Token', value: 'token' }
    ]
  }),

  mounted () {
    const self = this
    self.submit()
  },

  computed: {
    ...mapGetters('historicoRequisicaoPremioWeb', ['itens']),
    itemSelectKeys () { return { 'WebPrizeRequests:id': '' }}
  },

  methods: {
    ...mapActions('historicoRequisicaoPremioWeb', ['getItens']),
    submit () {
      this.loading = true
      this.getItens().then(() => {
        this.loading = false
      }).catch(e => {
        this.loading = false
        console.log(e)
      })
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
